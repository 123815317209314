<template>
  <div class="main-content">
    <h3 class="page-heading">Revenue Statement Report</h3>

    <div class="controls">
      <span class="item">
        <label for="group">Group</label>
        <select name="group" v-model="group">
          <option v-for="group in groups" :key="group">
            {{ group }}
          </option>
        </select>
      </span>
      <span class="item">
        <label for="dateFrom">From</label>
        <input
          type="date"
          name="dateFrom"
          v-model="period.dateFrom"
          @change="update()" />
      </span>
      <span class="item">
        <label for="dateTo">To</label>
        <input
          type="date"
          name="dateTo"
          v-model="period.dateTo"
          @change="update()" />
      </span>
      <span class="item">
        <label for="periods">/ Period</label>
        <select name="periods" @change="selectPeriod">
          <option
            v-for="invoicePeriod in getInvoicePeriods"
            :key="invoicePeriod.id"
            :value="invoicePeriod.id">
            {{ `${invoicePeriod.startDate} - ${invoicePeriod.endDate}` }}
          </option>
        </select>
      </span>
      <span class="item">
        <label>Download Data: </label>
        <button @click="this.downloadTable('csv')">CSV</button>
        <button @click="this.downloadTable('xlsx')">XLSX</button>
      </span>
    </div>

    <table v-if="group == 'None'">
      <thead>
        <tr>
          <th>Service</th>
          <th>Venue</th>
          <th>Vendor</th>
          <th>Outlet</th>
          <th>Payments</th>
          <th>Gross Rev</th>
          <th>SC Customer</th>
          <th>SC Outlet</th>
          <th>Adj Rev</th>
          <th>Refunds</th>
          <th>Cancels</th>
          <th>Converts</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="statement in statements" :key="statement.serviceId">
          <td>{{ statement.serviceId }}</td>
          <td>{{ this.venue(statement.venueId)?.name }}</td>
          <td>{{ this.vendor(statement.vendorId)?.name }}</td>
          <td>{{ this.outlet(statement.outletId)?.name }}</td>
          <td>{{ this.formatMoney(statement.payments.total) }}</td>
          <td>{{ this.formatMoney(statement.grossRevenue.total) }}</td>
          <td>{{ this.formatMoney(statement.serviceCharge.customer) }}</td>
          <td>{{ this.formatMoney(statement.serviceCharge.outlet) }}</td>
          <td>{{ this.formatMoney(statement.adjustedTotal) }}</td>
          <td>{{ this.formatMoney(statement.refunds) }}</td>
          <td>{{ this.formatMoney(statement.cancellations.total) }}</td>
          <td>{{ (statement.cartConversionRate * 100).toFixed(2) }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import { downloadBlob } from "@tucktrucks/core";

  export default {
    data() {
      return {
        group: "None",
        groups: ["None", "Venue", "Vendor", "Outlet"],
        period: { dateFrom: "2023-11-13", dateTo: "2023-11-19" },
        invoicePeriods: [],
        statements: [],
        venues: [],
        vendors: [],
        outlets: [],
      };
    },

    computed: {
      outletIds() {
        const outletIds = this.statements.map((x) => x.outletId).sort();

        return outletIds.filter((a, b, c) => c.indexOf(a) === b);
      },

      vendorIds() {
        const vendorIds = this.statements.map((x) => x.vendorId);

        return vendorIds.filter((a, b, c) => c.indexOf(a) === b).sort();
      },

      venueIds() {
        const venueIds = this.statements.map((x) => x.venueId);

        return venueIds.filter((a, b, c) => c.indexOf(a) === b).sort();
      },

      getInvoicePeriods() {
        const response = this.invoicePeriods.map((x) => x).sort();

        return response;
      },
    },

    methods: {
      outlet(id) {
        return this.outlets?.find((x) => x.id == id);
      },

      vendor(id) {
        return this.vendors?.find((x) => x.id == id);
      },

      venue(id) {
        return this.venues?.find((x) => x.id == id);
      },

      update() {
        this.downloadData();
      },

      downloadData() {
        store.state.apiPrivate.client.endpoints.statementsServices
          .query({
            dateFrom: this.period.dateFrom,
            dateTo: this.period.dateTo,
          })
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            if (response.status == 404) {
              return [];
            }

            return Promise.reject(response);
          })
          .then((data) => {
            this.statements = data;

            if (this.venueIds.length > 0)
              this.queryEntities(
                "venues",
                this.venueIds,
                (result) => (this.venues = result)
              );

            if (this.vendorIds.length > 0)
              this.queryEntities(
                "vendors",
                this.vendorIds,
                (result) => (this.vendors = result)
              );

            if (this.outletIds.length > 0)
              this.queryEntities(
                "outlets",
                this.outletIds,
                (result) => (this.outlets = result)
              );
          })
          .catch((ex) => {
            if (ex.status >= 400 && ex.status <= 499) {
              window.log.warn(
                "Could not download statements for these dates.",
                ex
              );

              useToast().warning(
                "Could not download statements for these dates."
              );
            } else {
              window.log.error("Failed to download statements.", ex);
              useToast().error("Failed to download statements.");
            }

            this.invoices = [];
          });
      },

      queryEntities(entity, ids, func) {
        const endpoint = store.state.apiPrivate.client.endpoints[entity]?.query;
        if (endpoint == null) {
          window.log.error(`Query method for ${entity} missing.`);
          useToast().error(`Query method for ${entity} missing.`);

          func([]);
          return;
        }

        store.state.apiPrivate.client.endpoints[entity]
          .query({
            ids: ids,
          })
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject(response);
          })
          .then((data) => {
            func(data);
          })
          .catch((ex) => {
            window.log.error(`Failed to download ${entity}.`, ex);
            useToast().error(`Failed to download ${entity}.`);

            func([]);
          });
      },

      selectPeriod(ev) {
        const selectedId = ev.target.selectedOptions[0].value;
        const selectedPeriod = this.invoicePeriods.find(
          (x) => x.id == selectedId
        );

        if (selectedPeriod == null) {
          window.log.error("Could not find a matching period.", ev);
          useToast().error("Could not find a matching period.");
        } else {
          this.period = {
            dateFrom: selectedPeriod.startDate,
            dateTo: selectedPeriod.endDate,
          };
        }
      },

      downloadPeriods() {
        store.state.apiPrivate.client.endpoints.invoicePeriods
          .query({})
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject(response);
          })
          .then((data) => {
            this.invoicePeriods = data ?? [];
            this.period = {
              dateFrom: this.invoicePeriods[0]?.startDate ?? "2023-11-13",
              dateTo: this.invoicePeriods[0]?.endDate ?? "2023-11-19",
            };
          })
          .catch((ex) => {
            window.log.error("Failed to download invoice periods.", ex);
            useToast().error("Failed to download invoice periods.");

            this.invoicePeriods = [];
          });
      },

      downloadTable(filetype) {
        store.state.apiPrivate.client.endpoints.statementsServices
          .query(
            {
              dateFrom: this.period.dateFrom,
              dateTo: this.period.dateTo,
            },
            `download=${filetype}`
          )
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.data; // Single DATA as not using JsonApi
            }

            return Promise.reject(response);
          })
          .then((data) => {
            const mimeType =
              filetype == "xlsx"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                : "text/csv";

            downloadBlob(
              data,
              `invoices-${this.period.dateFrom}-to-${this.period.dateTo}.${filetype}`,
              mimeType
            );
          })
          .catch((ex) => {
            window.log.error("Failed to download table of invoices.", ex);
            useToast().error("Failed to download table of invoices.");
          });
      },
    },

    mounted() {
      this.downloadData();
      this.downloadPeriods();
    },
  };
</script>

<styles lang="scss" scoped>
.controls {
  .item {
    padding: 1em;

    label,
    button {
      margin-right: 1em;
    }
  }
}

a {
  cursor: pointer !important;
  color: #ff7575 !important;

  &:hover {
    color: #ffa6a6 !important;
  }
}

.extra {
  color: #666;

  td {
    padding: 0 2em;
  }
}

.no-records {
  padding: 3em;
  font-size: 1.2em;
  text-align: center;
}
</styles>
